@import url(https://fonts.googleapis.com/css?family=Rajdhani|Roboto:300);
@media only screen and (max-width: 500px) {
  #main-menu .item {
    display: none !important;
  }

  #main-menu .toc.item {
    display: block !important;
  }
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #fill-screen {
    height: calc(100vh - 403px);
  }
}

/* Small Monitor */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  #fill-screen {
    height: calc(100vh - 384px);
  }
}

/* Large Monitor */
@media only screen and (min-width: 1200px) {
  #fill-screen {
    height: calc(100vh - 385px);
  }
}

body {
  font-family: 'Roboto', sans-serif !important;
}

p,
.ui.bulleted.list,
.ui.ordered.list,
ul.ui.list,
ol.ui.list {
  font-size: 1.28em;
}

#main-menu .item {
  font-size: 1.2em;
}

#main-menu-container {
  background-image: url(/static/media/europe_night_2.2aee29d6.jpg);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  padding: 0;
  margin: 0;
}

h1, h2, h3, h4, h5, a {
  font-family: 'Rajdhani', sans-serif !important;
}

#cut-rectangle {
  position: relative;
}

#cut-rectangle:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-top: 60px solid white;
  border-left: 60px solid #6435c9;
  width: 0;
  z-index: 1;
}

#cut-rectangle:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 60px solid white;
  border-right: 60px solid #6435c9;
  width: 0;
}

#hero1 {
  background: url(/static/media/001.df121e8a.png);
  background-repeat: no-repeat;
  background-size: auto;
  height: 470px;
  background-position: calc(100vw - 1000px) 0;
}

#test {
  width: 100vw;
  display: flex;
  justify-content: flex-end;

}
